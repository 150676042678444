import React from "react"
import { graphql } from "gatsby"
import { Query, SanityBlogPost } from "typings/graphql"
import { App } from "components/App/App"
import { setTheme } from "helpers/Theme"
import { BlogCategory } from "components/BlogCategory/BlogCategory"

type BlogTagTemplateProps = {
  data: Query
  pageContext: {
    posts: Partial<SanityBlogPost>[]
  }
}

const BlogTagTemplate = ({ data, pageContext }: BlogTagTemplateProps) => {
  const title = data.sanityBlogTag.title
  const path = data.sanityBlogTag.path

  const seoData = {
    seo: data.sanityBlogTag?.seo,
    path: `blog/tags/${path?.current}/`,
  }

  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )
  return (
    <App theme={theme} layout="page" mode="dark" seo={seoData}>
      <BlogCategory title={title} posts={pageContext.posts} path={path} />
    </App>
  )
}

export default BlogTagTemplate

export const tagQuery = graphql`
  query blogTagQuery($id: String!) {
    sanityBlogTag(id: { eq: $id }) {
      title
      path {
        current
      }
      seo {
        ...SanitySeo
      }
    }
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`
